import { useTheme } from '@material-ui/core';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import React from 'react';
import { Helmet, HelmetTags } from 'react-helmet';

import { Col, Typography } from '../components/UI';
import { STRIPE_PUBLIC_KEY } from 'utils/constants';

const StripeForm = () => {
  const location = useLocation();
  const theme = useTheme();

  const { sessionId } = queryString.parse(location.search);

  if (!sessionId) {
    return (
      <Col
        container
        style={{ backgroundColor: theme.palette.brand100, height: '100vh' }}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Col item lg={2}>
          <Typography style={{ paddingTop: theme.spacing(20) }}>
            Loading ...
          </Typography>
        </Col>
      </Col>
    );
  }

  /**
   * On load workaround
   * https://github.com/nfl/react-helmet/issues/146
   */
  const handleScriptInject = ({ scriptTags }: HelmetTags) => {
    if (scriptTags) {
      const scriptTag = scriptTags[0];

      scriptTag.onload = () => {
        const stripe = (window as any).Stripe(STRIPE_PUBLIC_KEY);

        stripe.redirectToCheckout({ sessionId }).then((result: any) => {
          if (result.error) {
            console.error(result.error.message);
          }
        });
      };
    }
  };

  return (
    <>
      <Helmet
        script={[{ src: 'https://js.stripe.com/v3' }]}
        onChangeClientState={(_newState, addedTags) =>
          handleScriptInject(addedTags)
        }
      />
    </>
  );
};

export default StripeForm;
